/**
 *  项目的配置文件
 */
// export const apiUrl = 'https://www.55sld.com/';//接口请求地址
export const apiUrl = "https://www.zjcfmcy88.com/"; //接口请求地址
export const chatUrl = "wss://139.9.137.183:1003"; //客服地址
export const mUrl = "http://139.9.137.183:1004"; //移动端网页地址
export const curLang = "zh"; //当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = "富民餐饮商城"; //浏览器顶部title
export const gdKey = ""; //高德web-js key
export const gdSecurityCode = ""; //高德web-js 安全密钥
export const statShowDebug = true; //是否开启统计的调试

/** copyright *** slodon *** version-v4.6.1 *** date-2023-02-28 ***主版本v4.6.1**/
